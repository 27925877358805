<template>
  <div id="instructions-page">
    <div class="instructions-header page-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-lg-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.instructions") }}</h3>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>

      <div v-if="error" class="row mx-0">
        <div class="col-12 col-lg-8 mx-auto">
          <h4 class="mb-2">{{ error }}</h4>
        </div>
      </div>

      <template v-else>
        <div class="row mx-0 mb-3">
          <div class="col-12 col-lg-8 mx-auto d-flex flex-column align-items-start">
            <b-form-select v-model="selectedCourseKey" id="course-select" class="mouse-pointer" :options="courseOptions"></b-form-select>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col-12 col-lg-8 mx-auto">
            <div class="accordion mb-4" role="tablist">
              <b-card v-for="(entry, eIdx) in translatedInstructions" :key="eIdx" no-body>
                <b-card-header header-tag="header" class="text-white p-2 question" :class="{ 'selected': collapsedAnswerId === `accordion-${eIdx}` }" role="tab">
                  <div
                    class="d-flex question-title mouse-pointer"
                    v-b-toggle="`accordion-${eIdx}`"
                    @click="toggleCollapsed(`accordion-${eIdx}`)"
                  >
                    <span>
                      <font-awesome-icon
                        :icon="['fas', collapsedAnswerId === `accordion-${eIdx}` ? 'caret-down' : 'caret-right']"
                        size="lg"
                        class="text-orange mr-2"
                      />
                      <span v-html="entry.title"></span>
                    </span>
                    <span class="ml-auto" @click="printInstructionsPdf($event, entry.title, entry.text)">
                      <span class="mr-1">Print</span>
                      <font-awesome-icon
                        :icon="['fas', 'print']"
                        size="lg"
                        class="text-orange mr-2"
                      />
                    </span>
                  </div>
                </b-card-header>

                <b-collapse :id="`accordion-${eIdx}`" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="bg-grey">
                    <b-card-text class="question-content">
                      <div v-html="entry.text"></div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </template>

    	<iframe id="ifrmPrint" src="" style="display:none;visibility:hidden"></iframe>
    </template>

  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "instructions",

	beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.selectedCourseKey = vm.$store.getters.getSelected.course ? vm.$store.getters.getSelected.course : '';
    });
  },

	created() {
    this.fetchInstructions();
  },

  data() {
    return {
      pendingComponentInit: false,

      instructions: {},
      collapsedAnswerId: '',
      error: '',

      products: [],
      selectedCourseKey: '',
    }
  },

	computed: {
  	isOnlineBuild() { return this.$store.getters['app/isOnline'] },

  	// Computed property that replaces instruction variables and provides translations if present
  	translatedInstructions() {
  		const instructions = cloneDeep(this.instructions);
    	Object.values(instructions).forEach(instr => {
    		instr.title = this.replaceVariables(instr.title);
    		instr.text = this.replaceVariables(instr.text);
    	})

  		const translatedInstr = Object.values(instructions).filter(instr => instr.language === this.$store.getters['user/getSelectedLanguage']);
  		return translatedInstr.length > 0 ? translatedInstr : Object.values(instructions).filter(instr => instr.language === 'en');
  	},

    // Select options to populate course select
    courseOptions() {
    	const courseOptions = [{ value: '', text: 'Select a course' }];
    	if (!this.isOnlineBuild) {
		    this.products.forEach(prod => {
		      prod.courses.forEach(course => courseOptions.push({ value: course.ckey, text: course.ctitle }))
		    });
		    return courseOptions;
		  }

		  // Online
		  return courseOptions
		  	.concat(this.$store.getters["userCourses/getCourses"]
		  	.map(c => { return { value: c.key, text: this.$store.getters['userCourses/getCourseTitle'](c.key) } }));
    },

    // Helper property that provides the values of some of the instructions variables
  	selectedProductCourse() {
      let selected = { cd: '', product: '', course: '' };

      if (!this.isOnlineBuild) {
		    this.products.forEach(prod => {
		      const selectedCourse = prod.courses.find(c => c.ckey === this.selectedCourseKey)
          if (selectedCourse) {
            selected = { cd: prod.pkey, product: prod.ptitle, course: selectedCourse.ctitle }
          }
		    })
		    return selected
		  }

		  // Online
		  const selectedCourseObj = this.$store.getters["userCourses/getCourses"].find(c => c.key === this.selectedCourseKey);
		  if (selectedCourseObj) {
        const selectedProduct = this.products.find(p => p.id === selectedCourseObj.cdId)
        const selectedProductTranslation = selectedProduct.translations.find(tr => tr.language === this.$store.getters['user/getSelectedLanguage']);
        const selectedProductTitle = selectedProductTranslation ? selectedProductTranslation.title : selectedProduct.title;
        selected = { cd: selectedProduct.cd.toUpperCase(), product: selectedProductTitle, course: this.$store.getters['userCourses/getCourseTitle'](selectedCourseObj.key) };
		 	}
		  return selected;
    },

    // Helper property that provides the value of the selected course's weekly target variable
    selectedCourseTarget() {
    	if (!this.isOnlineBuild) {
    		let weeklyTarget = 0
    		this.products.forEach(prod => {
		      const selectedCourse = prod.courses.find(c => c.ckey === this.selectedCourseKey)
		      if (selectedCourse) { weeklyTarget = selectedCourse.weeklyTarget; }
		    })
		    return weeklyTarget;
      }

      // Online
      return this.$store.getters["userCourses/getCourseWeeklyTarget"](this.selectedProductCourse.course);
    }
	},

  methods: {
    // Fetch Instructions
    async fetchInstructions() {
      try {
        this.pendingComponentInit = true;
        this.instructions = this.isOnlineBuild
          ? await this.$store.dispatch("fetchInstructions")
          : await this.$store.getters.getInstructions;

        await this.fetchProducts();
      } catch (e) {
        console.log(e);
        this.error = 'Instructions could not be retrieved.. Something went wrong'
      } finally {
        this.pendingComponentInit = false;
      }
    },

    // Fetch products (to be used to determine the values of the variables)
    async fetchProducts() {
    	try {
    		if (!this.isOnlineBuild) {
					this.products = await this.$store.dispatch('getProductCourses');
				} else {
					this.products = await this.$store.dispatch('products/fetchProducts');
				}

				return Promise.resolve();
    	} catch (e) {
    		console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.products'),
        });
    		return Promise.resolve();
    	}
    },

    // Helper function that replace variables with their computed values
    replaceVariables(content) {
      content = content.replaceAll('%%PRODUCT_KEY%%', this.selectedProductCourse.cd);
      content = content.replaceAll('__PRODUCT_KEY__', this.selectedProductCourse.cd);
      content = content.replaceAll('%%COURSE_TITLE%%', this.selectedProductCourse.course);
      content = content.replaceAll('%%PRODUCT_TITLE%%', this.selectedProductCourse.product);
      content = content.replaceAll('%%COURSE_WEEKLY_TARGET%%', this.selectedCourseTarget);
      return content;
    },

    // Toggle Instructions accordion entry open state
    toggleCollapsed(id) {
      this.collapsedAnswerId = this.collapsedAnswerId === id ? '' : id
    },

    printInstructionsPdf(e, title, text) {
  		e.stopPropagation();

      const ifrmPrint = document.getElementById('ifrmPrint');
			var html = `<h1>${title}</h1>`;
			html += `<div>${text}</div>`;
			html += '<scr'+'ipt>window.print();</scr'+'ipt>';
			ifrmPrint.setAttribute('src',`data:text/html;charset=utf-8,${html}`);
  	},

    showErrorAlert({ title, text }) {
    	this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  }
}
</script>

<style scoped>
.question {
  background-color: #1b96cf;
}
.question:hover {
  background-color: #fec801;
}
.question:hover svg {
  color: white;
}
.question-title:focus {
  outline: none;
}
.question.selected {
  font-weight: bold;
  background-color: #fec801;
}
.question.selected svg {
  color: white;
}
.instructions-header {
  background-image: url(../../assets/images/page-header/instructions.jpg);
}
.bg-grey {
	background-color: #F2F2F2;
}
</style>

